<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "dalie",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#视频设置用简易配置',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到打猎页",
                            "#按图1说明参数:",
                            "1.点击工具-自动攻击,设置输入套路快捷键,点击保存设置(图1)",
                            "2.切换回生活-打猎页,选择猎物(图2)",
                        ],
                        img:[
                            "2.png",
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#角色移动到指定猎物旁边,建议是两个猎物的点,如果猎物很多,会打死所有猎物后再剥皮,影响效率(图1)",
                            '#以上设置好后,开始任务',
                        ],
                        img:[
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
